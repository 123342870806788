/*** Project color generator ***/

/* color generator START */

// create colors & background colors from variables
@each $color-line, $color-item in $colors {

	// create color classes: e.g. .color-black { color: 'black'; }
	.#{"" + $color-line} {
		color: $color-item;
	}

	// create background color classes: e.g. .bg-color-black { background-color: 'black'; }
	.bg-#{"" + $color-line} {
		background-color: $color-item;
	}

	// create border color classes: e.g. .border-color-black { border-color: 'black'; }
	.border-#{"" + $color-line} {
		border-color: $color-item;
	}
}

/* color generator END */
