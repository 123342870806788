/*** Project subpages ***/
html {
  -webkit-font-smoothing: antialiased;
}

.main .alert-danger {
  background: none;
  border: 0;

  p {
    color: red;
		font-size: $project-default-font-size + 4;
  }
}

.main .alert-success {
  background: none;
  border: 0;

  p {
    color: #00c4db;
		font-size: $project-default-font-size + 4;
  }
}

#map {
  display: block;
  width: 100%;
  min-height: 324px;
}

.position-helper {
  position: absolute;
  top: -93px;
}

/* subpage name START */

.parallax-mirror {
  z-index: 1 !important;
}

.parallax-block {

  padding-top: 70px;

  @media (min-width: $screen-desktop) {
    padding-top: 0px;
  }

  .parallax-window {
    background: transparent;
    height: 100%;
    padding: 30px 0 30px 0;
    position: relative;
    display: -webkit-flex;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    flex-direction: column;
    -webkit-flex-direction: column;
    justify-content: center;
    -webkit-justify-content: center;

    @media (min-width: $screen-tablet) {
      height: 400px;
    }

    @media (min-width: $screen-desktop) {
      height: 550px;
    }
  }

  .parallax-text {
    /*position: absolute;*/
    z-index: 1;
    text-align: center;
    width: 100%;
    padding-right: 22%;
    padding-left: 22%;
    @media (max-width: $screen-tablet) {
      padding-right: 15%;
      padding-left: 15%;
    }

    h1 {
      color: $color-white;
      margin-bottom: 28px;
      font-size: 28px;
    }

    p {
      line-height: 28px;
      margin-bottom: 0;
      color: $color-white;
      font-weight: 100;
      font-size: $project-default-font-size;
      font-family: $project-default-font-family-1-REGULAR;
    }

    a {
      text-decoration: none;
    }

    .icomoon {
      bottom: 10px;
      position: absolute;
      color: $color-white;
      font-size: $project-default-font-size + 24;
    }
  }

}

.custom-list-style{
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      display: table;
      width: 100%;
      border-bottom: 1px solid #e9e9e9;
      table-layout: fixed;
      padding: 5px 0;

      div {
        display: table-row;

        .fa {
          font-size: 16px;
          width: 25px;
          text-align: right;
        }

        & * {
          display: table-cell;
        }

        span:last-child{
          padding-left: 15px;
          text-align: left !important;
        }
      }
    }
  }
}

.slick-carousel {

  .slick-prev {
    z-index: 2;
    left: 13%;
    width: 80px;
    height: 55px;
    @media (max-width: $screen-tablet) {
      left: 5%;
    }
    &:before {
      content: url("../img/slider_arrow_prev.svg");
      display: inline-block;
      width: 80px;
      @media (max-width: $screen-tablet) {
        width: 45px;
      }
    }
  }

  .slick-next {
    z-index: 2;
    right: 13%;
    width: 80px;
    height: 55px;
    @media (max-width: $screen-tablet) {
      right: 5%;
    }
    &:before {
      content: url("../img/slider_arrow_next.svg");
      display: inline-block;
      width: 80px;
      @media (max-width: $screen-tablet) {
        width: 45px;
      }
    }
  }

  .slick-slide {
    outline: 0;
    position: relative;

    a {
      display: block !important;
    }
  }

  .overlay{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(17, 25, 45, 0.7);
    @include css3-transition();

    @media (max-width: $screen-desktop) {
      background: rgba(17, 25, 45, 0);
    }

  }

  .item.slick-slide.slick-current.slick-active.slick-center .overlay{
    visibility: hidden;
    background: rgba(17, 25, 45, 0);
  }
}


.search-wrap.search-subpage {
  height: auto;
  @media (min-width: $screen-desktop) {
    height: 0;
  }
  .search-bar {
    position: relative;
    top: 0;
    z-index: 1;
    @media (min-width: $screen-desktop) {
      top: -40px;
    }
    @media (min-width: $screen-lg-desktop) {
      //top: -80px;
      top: -40px;
    }
    @media (min-width: $screen-xl-desktop) {
      top: -40px;
    }
  }
}

.search-wrap-outer {
  display: flex;
  margin-bottom: 30px;

  @media (max-width: $screen-phone) {
    display: block;
  }

  .search-list-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    h3 {
      font-size: 25px;
      font-family: $project-default-font-family-1-PT-BOLD;

      @media (max-width: $screen-phone) {
        margin-bottom: 22px;
      }
    }

    p {
      @media (max-width: $screen-phone) {
        text-align: justify;
        margin-bottom: 18px;
      }
    }

    a {
      @media (max-width: $screen-phone) {
        margin: 0 auto;
      }
    }
  }

  img {
    @media (max-width: $screen-phone) {
      width: 100%;
    }
  }
}

.article {

  p {
    font-size: 16px;
    line-height: 28px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1, h2, h3, h4, h5, h6, ol, ul, p  {
    margin-bottom: 27px !important;
  }

}

.vente-details {

  .content {
    @media (max-width: $screen-tablet) {
      margin-bottom: 30px;
    }

    h3 {
      margin-bottom: 26px;
    }
    li,p {
      font-size: 16px;
      line-height: 28px;
    }
    p {
      text-align: justify;
    }

  }

  .contact {
    min-width: 488px;
    @media (max-width: $screen-tablet) {
      text-align: center;
    }

    // mobile
    @media (max-width: $screen-xs-max) {
      min-width: auto;
    }

    p{
      font-size: $project-default-font-size - 2;
      line-height: $project-default-line-height + 2;
    }

    .name {
      font-family: $project-default-font-family-BOLD;
      font-size: $project-default-font-size;
      line-height: $project-default-line-height;
      margin-bottom: 0;
      @media (max-width: $screen-tablet) {
        margin-top: 30px;
      }
    }
    .title{
      font-size: $project-default-font-size - 2;
      line-height: $project-default-line-height + 2;
    }
    .fa {
      width: 30px;
    }
    .phone {
      color: $color-black;
      margin-bottom: 20px;
      white-space: nowrap;
    }

    .btn {
      margin-top: 68px;
      @media (max-width: $screen-tablet) {
        margin-top: 22px;
      }
    }

    .img-wrap {
      float: left;
      margin-right: 30px;
      @media (max-width: $screen-tablet) {
        float: none;
        text-align: center;
        margin-right: 0;
      }
    }
  }

  .custom-list-style {
    ul li {
      font-family: "ProximaNova-Bold_1", Arial, Helvetica, sans-serif;

      span:last-child {
        font-family: "ProximaNova-Regular_1", Arial, Helvetica, sans-serif;
				text-align: right;
      }
    }
  }

  .right {
    @media (max-width: $screen-tablet) {
      display: flex;
      flex-direction: column;
    }

    .row:nth-child(1) {
      order: 2;
    }

    .row:nth-child(2) {
      order: 1;
    }

  }
}

.property-parameters {
  padding: 0 30px 0 30px;

  .content{
    h3{
      margin-bottom: 26px;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;

    & * {
      width: 100%;
      text-align: center;
    }

    span{
      font-size: $project-default-font-size - 2;
    }

    span:first-child {
      font-family: $project-default-font-family-BOLD;
      font-weight: bold;
      border-bottom: 1px solid #e9e9e9;
    }
  }
}

@media (min-width: $screen-xl-desktop) {
  .container.promotions {
    width: 1440px;
  }
}

.promotions {

  h3 {
    margin-bottom: 26px;
  }

  .row {
    display: block;
    @media (min-width: $screen-desktop) {
      display: flex;
    }

    .col-lg-5{
      padding: 0;

      .inner{
        padding: 15px;
        background-color: #f8f8f8;

        // desktop
        @media (min-width: $screen-lg-min) {
          margin-right: 15px;
        }

        // tablet vertical, mobile
        @media (max-width: $screen-sm-max) {
          margin-left: 15px;
          margin-right: 15px;
        }
      }
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      .col-lg-5{
        .inner{
          // desktop
          @media (min-width: $screen-lg-min) {
            margin-right: 0px;
            margin-left: 15px;
          }
        }
      }

      .col-lg-7{
        .inner{
          // desktop
          @media (min-width: $screen-lg-min) {
            margin-right: 15px;
          }
        }

        // desktop, tablet horizontal
        @media (min-width: $screen-md-min) {
          padding-left: 0;
        }
      }

      //.item {
      //  margin-left: 30px;
      //  @media (max-width: $screen-desktop) {
      //    margin-left: 0px;
      //    margin-bottom: 30px;
      //  }
      //
      //}
    }
    &:nth-child(odd) {
      .col-lg-7{
        // desktop, tablet horizontal
        @media (min-width: $screen-md-min) {
          padding-right: 0;
        }
      }

      //.item {
      //  margin-right: 30px;
      //  @media (max-width: $screen-desktop) {
      //    margin-right: 0;
      //    margin-bottom: 30px;
      //  }
      //
      //}
    }

    p {
      font-size: 16px;
      line-height: 28px;

      // laptop, tablet, mobile
      @media (max-width: $screen-lg-max) {
        line-height: 28px;
      }
    }
  }

  .item {
    position: relative;
    overflow: hidden;

    &.sold-out .btn{
      pointer-events: none;
    }

    .item-inner {
      position: relative;
    }

    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(17, 25, 45, 0.7);
      opacity: 0;
      color: $color-white;
      margin: 0;
      padding: 30px;
    }

    .btn {
      position: absolute;
      bottom: 30px;
      right: 30px;
      z-index: 1;

      @media (max-width: $screen-xs-max) {
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 1;
        padding: 0 15px;
        height: 30px;
        line-height: 29px;
      }
    }

    p {
      font-family: $project-default-font-family-1-REGULAR;
      font-weight: 100;
    }
    span {
      font-family: $project-default-font-family-BOLD;
    }

    img {

      height: 100%;
      max-height: 100%;
      width: 100%;

      @media (max-width: $screen-phone) {
        margin-bottom: 15px;
      }

      @media (max-width: $screen-desktop) {
        display: block;
        width: 100% \9;
        max-width: 100%;
        height: auto;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
    .item {

      &:hover {
        .overlay {
          opacity: 1;
          @include css3-transition();
        }
      }
    }

  .overlay p {
    margin-bottom: 10px;
    line-height: 1;
    }
  }

.conseil {

  h3 {
    margin-bottom: 27px;
    font-size: 23px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: -6px;
  }

  .icons {
    width: 79px;
    height: 79px;
    margin-bottom: 45px;
  }

  &-slider{
    cursor: pointer;

    .slick-slide{
      outline: none;
    }

    &-arrow{
      position: absolute;
      bottom: -40px;
      color: $color-dark-gray;
      font-size: 22px;
      @include css3-transition('color', '0,5s', 'ease');

      &.slick-disabled{
        display: none !important;
      }

      // desktop
      @media (min-width: $screen-lg-min) {
        &:hover,
        &:focus {
          @include css3-opacity(0.5);
        }
      }

      &-left{
        left: 15px;
      }

      &-right{
        right: 15px;
      }
    }
  }

  .item{
    &:before {
      // mobile + tablet
      @media (max-width: $screen-md-max) {
        content: '+';
        background: rgba(255, 255, 255, 0.5);
        font-size: 30px;
        color: rgba(255, 255, 255, 0.8);
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0px;
      }
    }
  }

  .overlay {
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(17, 25, 45, 0.9);
    opacity: 0;
    color: $color-white;
    padding: 30px;
    overflow-y: auto;
    //overflow-y: scroll;
    -ms-overflow-style: none;  // IE 10+
    //overflow: -moz-scrollbars-none;  // Firefox

    .description {
      text-align: justify;
      margin-top: 28px;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }



  .item {

    &:hover {
      .overlay {
        text-decoration: none;
        opacity: 1;
        color: $color-white;
        @include css3-transition();
      }
    }

    p {
      font-family: $project-default-font-family-1-REGULAR;
      font-weight: 100;
      line-height: 28px;
      margin-bottom: 0;
      //&:last-child {
      //  margin-top: 45px;
      //}
    }
    span {
      font-family: $project-default-font-family-BOLD;
    }
  }
  .col-xs-12.col-sm-4.margin-bottom-default {
    @media (min-width: $screen-desktop) {
      padding-left: 45px;
      padding-right: 45px;
    }
  }
}

.contact-block {

  p{
    font-size: $project-default-font-size - 2;
  }

  .fa {
    width: 30px;
  }
  .link {
    color: $color-black;
    margin-bottom: 20px;
    &:hover {
      opacity: 0.5;
      color: $color-black;
      text-decoration: none;
    }
  }

  textarea.form-control {
    background-image: -webkit-linear-gradient(left, white 0px, transparent 0px),
    -webkit-linear-gradient(right, white 0px, transparent 0px),
    -webkit-linear-gradient(white 40px, #e9e9e9 40px, #e9e9e9 41px, white 41px);
    background-image: -moz-linear-gradient(left, white 0px, transparent 0px),
    -moz-linear-gradient(right, white 10px, transparent 10px),
    -moz-linear-gradient(white 40px, #e9e9e9 40px, #e9e9e9 41px, white 41px);
    background-image: -ms-linear-gradient(left, white 0px, transparent 0px),
    -ms-linear-gradient(right, white 10px, transparent 0px),
    -ms-linear-gradient(white 40px, #e9e9e9 40px, #e9e9e9 41px, white 41px);
    background-image: -o-linear-gradient(left, white 0px, transparent 100),
    -o-linear-gradient(right, white 0px, transparent 0px),
    -o-linear-gradient(white 40px, #e9e9e9 40px, #e9e9e9 41px, white 41px);
    background-image: linear-gradient(left, white 0px, transparent 0px),
    linear-gradient(right, white 0px, transparent 0px),
    linear-gradient(white 40px, #e9e9e9 40px, #e9e9e9 41px, white 41px);
    background-size: 100% 100%, 100% 100%, 100% 52px;
    //border-top: 1px solid #e9e9e9;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-radius: 0;
    line-height: 46px;
    font-size: $project-default-font-size;
    padding: 8px 0;
    width: 100%;
    height: 268px;
    resize: none;
    overflow: hidden;
  }

  textarea.form-control:focus {
    outline: none;
    border-color: #e9e9e9;
  }

  .form-group {
    margin-bottom: 47px;
  }

  .form-group.input-text{
    display: flex;
    margin-bottom: 29px;
    align-items: stretch;
    justify-content: center;
    flex-direction: row;

    @media (max-width: $screen-tablet) {
      margin-bottom: 33px;
    }

    label{
      padding-top: 10px;
      display: flex;
      border-bottom: 1px solid #00c4db;
      margin-bottom: 0;
      font-weight: normal;
    }
  }

  label{
    padding-top: 10px;
    border-bottom: 1px solid #00c4db;
    margin-bottom: 0;
    padding-bottom: 8px;
    font-weight: normal;
  }

  input[type="text"] {
    border-bottom: 1px solid #e9e9e9;
    display: flex;
  }

  .btn {
    float: right;
    background: #00c4db;
    color: #fff;
    &:hover {
    }
  }

  .data-wrap {
    h3 {
      margin-bottom: 35px;
      letter-spacing: 0;
    }
    p {
      margin-bottom: 15px;
    }
  }

  .form-align {
    margin-top: -15px;

    label, input, textarea{
      font-size: $project-default-font-size - 2;
    }
  }
}

.equipe {

    &-slider{
      margin-left: -15px;
      margin-right: -15px;
    }

  .col-xs-12{
    .inner{
      padding: 20px 15px 30px 15px;
      background-color: $color-gray;
    }
    // tablet + desktop
    @media (min-width: $screen-sm-min) {

        &.with-padding{
            padding-left: 30px;
            padding-right: 30px;
        }
//      &:first-child{
//        padding-right: 30px;
//      }
//      &:last-child{
//        padding-left: 30px;
//      }
    }
  }

  h3 {
    margin-bottom: 0;
    margin-top: -5px;
  }

  img {
    margin-bottom: 32px;

    @media (max-width: $screen-tablet) {
      max-width: 300px;
    }

    @media (max-width: $screen-phone) {
      max-width: 100%;
    }
  }

  p.title {
    margin-bottom: 32px;
    line-height: 36px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0;
  }

  .desc p {
    margin-bottom: -14px;
    text-align: justify;
    line-height: 28px;
  }

  .wrap {

    margin-bottom: 19px;

    @media (max-width: $screen-tablet) {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }

    .icon-brain {
      //line-height: 38px;
      position: relative;
      //padding: 0 0 18px 55px;
      //min-width: 260px;
      &:before {
        content: '';
        background: url(../img/gear_brain.svg) no-repeat;
        width: 23px;
        height: 30px;
        display: inline-block;
//        position: absolute;
//        left: 2px;
//        top: 6px;
      }
    }
    .icon-hat {
      //line-height: 38px;
      position: relative;
      //padding: 0 0 18px 55px;
      //min-width: 260px;
      &:before {
        content: '';
        background: url(../img/hat.svg) no-repeat;
        width: 30px;
        height: 30px;
        display: inline-block;
//        position: absolute;
//        left: 0;
//        top: 6px;
      }
    }
  }
}

.not-found {
  margin-top: 20%;
  height: 100vh;

  h1 {
    font-size: 72px;
  }
}

/* subpage name END */


//search result page

.search-result-page {

  min-height: calc(100vh - 186px);

  @media (max-width: $screen-desktop) {
    padding-top: 70px;
  }

  background: #f3f4f5;

  .title {
    margin-bottom: 10px;
  }

  .num {
    margin-bottom: 0;
    line-height: 10px;
  }

  .search-wrap {
    height: auto;
  }

  .search-wrap-outer:last-child {
    margin-bottom: 0;
  }

  .search-wrap-outer.sold-out {
    a.btn {
      pointer-events: none;
    }
  }
}

//search result page


//actualites page
.recent-block{
  h2{
    font-size: 30px;
    margin-bottom: 25px;
  }
  a.col-xs-12{
    text-decoration: none;
    cursor: pointer;
    @include css3-opacity(1);

    &:before {
      // mobile + tablet
      @media (max-width: $screen-md-max) {
        content: '+';
        background: rgba(255, 255, 255, 0.5);
        font-size: 30px;
        color: rgba(255, 255, 255, 0.8);
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        display: inline-block;
        position: absolute;
        top: 0;
        right: 15px;
      }
    }

    .bg-image{
      @include project-default-transition();
    }

    // desktop
    @media (min-width: $screen-lg-min) {
      &:hover,
      &:focus {
        text-decoration: none;

        .bg-image{
          @include css3-opacity($project-default-opacity-HOVER);
        }
      }
    }
  }
}

.equipe-slider{
  //margin-bottom: 100px;

  *,
  *:focus{
    outline: 0 !important;
  }

  &-arrow{
    position: absolute;
    top: 50%;
    margin-top: -13px;
    color: $color-dark-gray;
    font-size: 22px;
    z-index: 3;
    @include css3-transition('color', '0,5s', 'ease');

    &.slick-disabled{
      display: none !important;
    }

    // desktop
    @media (min-width: $screen-lg-min) {
      &:hover,
      &:focus {
        @include css3-opacity(0.5);
      }
    }

    &-left{
      left: 0;
    }

    &-right{
      right: 0;
    }
  }
}

