/*** Project index page ***/

/* index START */

h1, h2, h3, h4, h5 {
  font-family: $project-default-font-family-1-PT-BOLD;
  letter-spacing: 1px;
}

h1, .h1 {
  font-size: 45px;
}

h2, .h2{
  font-size: 40px;
}

h3, .h3 {
  font-size: 25px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
body {
  padding-top: 0;
  @media (min-width: $screen-desktop) {
    padding-top: 93px;
  }
}

div#ccm-toolbar {
  z-index: 9999;
}

#site-logo {
  padding-bottom: 3px;
  padding: 9px 30px 13px 30px;

  // tablet horizontal, laptop
  @media (min-width: $screen-md-min) and (max-width: $screen-lg-max) {
    padding: 9px 15px 13px 15px;
  }

  a {
    padding: 0;
  }

  svg {
    width: 100%;
    max-width: 200px;
    max-height: 57.7px;
  }
  img {
    height: 100%;
  }
}

.main .highlight {
  color: #00c4db;
  font-family: inherit;
}

.navbar-brand {
  width: 150px;
  height: auto;
  margin-top: 5px;

  img {
    width: 100%;
    height: auto;
  }

  svg{
    display: inline-block;
    vertical-align: middle;
  }
}

.navbar {
  //position: relative;
  min-height: 50px;
  margin-bottom: 0;
  @include css3-transition();

  svg {
    path {
      @include css3-transition();
    }
  }
}

.navbar.invert {
  background-color: $color-dark-blue;
  .nav.navbar-nav ul li a {
    color: #fff !important;
    }

  svg {
    path {
      fill: #fff;
    }
  }
}

.navbar-fixed-top, .navbar-fixed-bottom {
  z-index: 2;
}

@media (min-width: $screen-desktop) {
  .navbar{ // navbar class
    position:fixed;
  }
}

.navbar-toggle {
  margin-top: 20px;
}

.navbar-default {
  border: none;

  .navbar-nav{
    @media (min-width: $screen-desktop) {
      float: none;
    }

    // HD
    @media (min-width: $screen-xl-min) {
      padding-left: 18px;
    }

    // laptop
    @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
      padding-left: 13px;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      font-size: $project-default-font-size - 2;

      @media (min-width: $screen-desktop) {
        height: 93px;
        line-height: 93px;
      }

      li{
        padding: 9px 42px 13px 42px;

        // tablet horizontal, desktop
        @media (min-width: $screen-md-min) {
          display: inline-block;
          vertical-align: bottom;
        }

        // HD
        @media (min-width: $screen-xl-min) {
          padding: 9px 42px 13px 42px;
        }

        // tablet horizontal, laptop
        @media (min-width: $screen-md-min) and (max-width: $screen-lg-max) {
          padding: 9px 21px 13px 21px;
        }

        a {
          display: block;
          color: $color-black;
          line-height: 1;
        }
      }
    }


    // this is for a language selector, dont delete please
    //li:last-child {
    //  position: absolute;
    //  right: 8px;
    //  bottom: 13px;
    //  a {
    //    padding: 0 10px 0 0;
    //  }
    //}

  }
}

.property-select, .who-are-we {

  .head {
    h2 {
      margin-bottom: 24px;
      margin-top: -6px;
    }
    p {
      margin-bottom: -13px;
      line-height: 28px;
    }
  }

  .desc {
    align-items: center;
    background: rgba(17, 25, 45, 0.9);
    color: $color-white;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    opacity: 0;
    padding: 0 30px;
    position: absolute;
    right: 0;
    top: 0;

    h3  {
      margin-bottom: 20px;
      width: 100%;
      font-size: 23px;
    }

    p {
      font-size: 16px;
      width: 100%;
      margin-bottom: 20px;

      @media (max-width: $screen-xs-max) {
        font-size: 13px;
        line-height: normal;
      }
    }
  }

  .item {

    &:before {
      @media (max-width: $screen-xs-max) {
        content: '+';
        background: rgba(255, 255, 255, 0.5);
        font-size: 30px;
        color: rgba(255, 255, 255, 0.8);
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        display: inline-block;
        position: absolute;
        top: 0;
        right: 15px;
      }
    }

    &:hover, &:focus, &:active {
      .desc {
        opacity: 1;
        @include css3-transition();
      }
    }

    &.sold-out {
      .desc {
        background: rgba(43, 46, 57, 0.7);
      }
      &:hover, &:focus, &:active {
        .desc {
          opacity: 1;
          @include css3-transition();
        }
        .btn {
          pointer-events: none;
        }
      }
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.who-are-we {

  h2 {
    font-size: 30px;
    margin-bottom: 25px;
  }

  p{
    margin-bottom: 0;
  }

  .item:before{
    background-color: rgba(0,0,0,0.5);
  }
  .item .desc {
    margin: 0 15px;

    .btn-wrap {
      position: absolute;
      bottom: 0;
    }
  }

  .item:first-of-type {
    @media (max-width: $screen-tablet) {
      margin-bottom: 30px;
    }
  }
}

//.news{
//  .col-xs-12 h2{
//    margin-bottom: 66px;
//  }
//}

.title-block {
  h2 {
    font-size: 30px;
    line-height: 1;
    margin: -6px 0 -5px 0;
  }
}

.img-responsive {
  margin-right: auto;
  margin-left: auto;
}

.recent-block {
  margin-bottom: 36px;
  padding-left: 30px;
  padding-right: 30px;

  @media (max-width: $screen-tablet) {
    padding-left: 15px;
    padding-right: 15px;
  }

  .col-xs-12.col-sm-12.col-md-4 {
    margin-bottom: 30px;
  }

  .item {
    padding: 15px 40px 30px 40px;

    p {
      font-size: 16px;
      line-height: 28px;
      text-align: justify;
    }
  }

  small {
    margin-bottom: 15px;
    display: inline-block;
  }

  h3 {
    margin-bottom: 20px;
    font-size: 23px;
  }
  &.margin-top-30 {
    margin-top: 30px;
    margin-bottom: 36px;

    .col-xs-12.col-sm-6.col-md-4.margin-bottom-default {
      margin-bottom: 30px;
    }
  }
}

footer {
  padding: 0 30px;
  height: 93px;
  color: $color-white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: $screen-desktop) {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  & > div {
    display: flex;
    align-items: center;
  }

  .social a {
    font-size: 23px;
    color: $color-white;
    padding: 0 15px;
    // temporary disable click
  }

  //.social a:not(.linkedin){
  //  pointer-events: none;
  //}

  a {
    color: $color-white;
    &:hover {
      text-decoration: none;
      color: $color-dark-gray;
    }
  }
}

.search-wrap {

  @media (min-width: $screen-desktop) {
    height: 39px;
  }

  .search-bar {
    font-family: $project-default-font-family-1-PT-BOLD;
    align-items: center;
    background: #11192D;
    color: white;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    top: 0;
    z-index: 1;

    @media (max-width: $screen-phone) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media (max-width: $screen-desktop) {
      position: initial;
    }

    form {
      display: flex;
      width: 100%;
    }

    input {
      background: $color-dark-blue;
      color: $color-white;
      border: 0;
      height: 80px;
      line-height: 80px;
      width: 100%;
      font-size: 23px;

      &::-moz-placeholder{
        @include css3-opacity(1);
      }

      &:focus, &:active {
        outline: 0;
      }

      @media (max-width: $screen-phone) {
        font-size: 20px;
      }
    }

    /* Reset `button` and button-style `input` default styles */
    input[type="submit"],
    input[type="reset"],
    input[type="button"],
    button {
      width: 52px;
      background: none;
      border: 0;
      color: inherit;
      /* cursor: default; */
      font: inherit;
      line-height: normal;
      overflow: visible;
      padding: 0;
      -webkit-appearance: button; /* for input */
      -webkit-user-select: none; /* for button */
      -moz-user-select: none;
      -ms-user-select: none;
      &:hover, &:active, &:focus {
        outline: 0;
      }
    }
    input::-moz-focus-inner,
    button::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    .fa-search {
      height: 42px;
      line-height: 42px;
      padding-left: 30px;
      border-left: 1px solid #00C4DB;

      @media (max-width: $screen-phone) {
        padding-left: 15px;
      }
    }
  }
  ::-webkit-input-placeholder {
    color: $color-white;
  }

  :-moz-placeholder { /* Firefox 18- */
    color: $color-white;
  }

  ::-moz-placeholder { /* Firefox 19+ */
    color: $color-white;
  }

  :-ms-input-placeholder {
    color: $color-white;
  }
}



.container-fluid.search-subpage {
  height: 0;

  .search-bar {
    top: -47px;
    background: #FFFFFF;

    input {
      background: #FFFFFF;
      color: #11192D;
    }

    .fa-search {
      border-left: 1px solid #00C4DB;
      color: #11192D;
    }
  }

  ::-webkit-input-placeholder {
    color: #11192D;
  }

  :-moz-placeholder { /* Firefox 18- */
    color: #11192D;
  }

  ::-moz-placeholder { /* Firefox 19+ */
    color: #11192D;
  }

  :-ms-input-placeholder {
    color: #11192D;
  }
}

/*.carousel .item{

  a.btn {
    @media (min-width: $screen-tablet) {
      display: block;
    }
  }

  h1 {
    margin-bottom: 15px;

    @media (min-width: $screen-tablet) {
      display: block;
      margin-bottom: 23px;
    }
  }
}

.carousel .item img {
  margin: 0 auto;
}
*/

/*
  .carousel-caption {
    position: absolute;
    top: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    h1 {
      font-size: 20px;
      margin-bottom: 21px;

      @media (min-width: $screen-desktop) {
        margin-bottom: 15px;
        font-size: 28px;
      }
    }

    p {
      @media (max-width: $screen-desktop) {
        display: none;
      }
      @media (min-width: $screen-lg-desktop) {
        margin-bottom: 50px;
      }
    }

    a.btn.btn-default {
      &:hover {
        border-color: #fff;
        color: #fff;
        opacity: 1;
      }
    }
  }

  .carousel-indicators {
    z-index: 9999;
    display: none;

    @media (min-width: $screen-tablet) {
      display: block;
    }

    @media (min-width: $screen-desktop) {
      display: block;
      bottom: 15%;
      margin-bottom: 0;
    }

    @media (min-width: $screen-lg-desktop) {
      display: block;
      bottom: 22%;
      margin-bottom: 0;
    }

    li {
      margin: 1px 5px;
    }

    .active {
      margin: 1px 5px;
    }
  }

  .carousel {
    height: calc(100vh - 93px);
    width: 100%;
    overflow:hidden;
    z-index:0;
  }

  .carousel .carousel-inner img {
    display:block;
    object-fit: cover;
    height: 100vh;
    width: 100vw;
  }
*/

.parallax-block.full-size {
  .parallax-window {
    object-fit: cover;
    height: calc(100vh - 93px);
    width: 100vw;
    z-index: 1;

    // mobile
    @media (max-width: $screen-xs-max) {
      height: auto;
      padding-top: 35px;
      padding-bottom: 35px;
    }

    .parallax-text {
      h1 {
        margin-bottom: 33px;
      }

      p {
        margin-bottom: 40px;
      }

      a.btn.btn-default {
        &:hover {
          border-color: #fff;
          color: #fff;
          opacity: 1;
        }
      }
    }
  }
}

.main .btn.btn-primary {
  color: $color-white;
  border-color: $color-white;
}

.fake-position {
  -ms-transform: translate(0, -93px); /* IE 9 */
  -webkit-transform: translate(0, -93px); /* Safari */
  transform: translate(0, -93px);

  @media (max-width: $screen-tablet) {
    -ms-transform: translate(0, -50px); /* IE 9 */
    -webkit-transform: translate(0, -50px); /* Safari */
    transform: translate(0, -50px);
  }
}

a.scroller {
  text-decoration: none;
  position: absolute;
  z-index: 10;
  bottom: 3%;
  width: 100%;
  text-align: center;
  color: $color-white;
  font-size: 14px;

  &:hover, &:focus {
    text-decoration: none;
    color: $color-light-gray;
  }

  @media (max-width: $screen-desktop) {
    display: none;
  }

  .icomoon {
    color: $color-white;
    font-size: 40px;
  }
}




.what-to-sell {
  h3 {
    margin-bottom: 31px;
    font-size: 23px;
  }

  p {
    font-size: 16px;
    margin-bottom: 36px;
    text-align: center;
  }

  .col-xs-12.col-md-4 {
    padding: 0px 45px;
  }

  a.btn  {
    margin-bottom: 10px;
  }
}

.pulse {

  & > .row {
    display: block;

    @media (min-width: $screen-desktop) {
     display: flex;
    }
  }

  .col-xs-12.col-md-4.margin-bottom-default {
    padding-bottom: 45px;

    @media (max-width: $screen-desktop) {
      display: block;
      padding-bottom: 0;
    }

    a.btn {
      margin-bottom: 0;

      @media (max-width: $screen-desktop) {
        position: relative;
      }
    }

    div.text-center {
      width: 100%;
      /* height: 45px; */
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      @media (max-width: $screen-desktop) {
        position: relative;
      }
    }
  }

  h3 {
    margin-bottom: 31px;
    font-size: 23px;
  }

  p {
    font-size: 16px;
    margin-bottom: 36px;
    text-align: center;
  }

  .col-xs-12.col-md-4 {
    padding: 0px 45px;
  }

  a.btn  {
    margin-bottom: 10px;
  }

  .who-are-we{

  }
}

/* index END */
