/*** Project forms ***/

/* forms START */

.main {

	// form groups
	.form-group {
		margin-bottom: $project-form-group-margin;
	}

	// form control
	.form-control {
		@include css3-border-radius($project-form-control-border-radius);
		@include css3-placeholder($project-form-control-color-placeholder);
		@include project-default-transition();
		@include project-form-control-box-shadow();
		background-color: $project-form-control-background-color;
		border-color: $project-form-control-border-color;
		border-style: solid;
		border-width: $project-form-control-border-width;
		color: $project-form-control-color;
		font-family: $project-form-control-font-family;
		font-size: $project-form-control-font-size;
		padding: $project-form-control-padding;

		&:focus {
			@include css3-placeholder($project-form-control-color-placeholder-FOCUS);
			@include project-form-control-box-shadow-FOCUS();
			background-color: $project-form-control-background-color-FOCUS;
			border-color: $project-form-control-border-color-FOCUS;
			color: $project-form-control-color-FOCUS;
		}

		// small
		&.input-sm {
			@include css3-border-radius($project-form-control-border-radius-SMALL);
			border-width: $project-form-control-border-width-SMALL;
			font-size: $project-form-control-font-size-SMALL;
			padding: $project-form-control-padding-SMALL;
		}

		// large
		&.input-lg {
			@include css3-border-radius($project-form-control-border-radius-LARGE);
			border-width: $project-form-control-border-width-LARGE;
			font-size: $project-form-control-font-size-LARGE;
			padding: $project-form-control-padding-LARGE;
		}

		// form control fake to file upload skin
		&-fake {
			height: $project-form-control-height;
			line-height: $project-form-control-height;
			overflow: hidden;
			padding-bottom: 0 !important;
			padding-top: 0 !important;
			text-align: left;

			* {
				display: inline-block;
				line-height: 1;
				margin: -3px 0 0 0;
				padding: 0;
				vertical-align: middle;
				width: 100%;

				.ie & {
					margin-top: -4px;
				}
			}

			// small
			&.input-sm {
				height: $project-form-control-height-SMALL;
				line-height: $project-form-control-height-SMALL;

				.blackberry & {
					line-height: $project-form-control-height-SMALL;
				}
			}

			// large
			&.input-lg {
				height: $project-form-control-height-LARGE;
				line-height: $project-form-control-height-LARGE;

				.blackberry & {
					line-height: $project-form-control-height-LARGE;
				}
			}
		}
	}

		// input
		input.form-control {
			@include css3-prefix(appearance, none);
			height: $project-form-control-height;
			line-height: 1;

			.blackberry & {
				line-height: $project-form-control-height;
			}

			// small
			&.input-sm {
				height: $project-form-control-height-SMALL;

				.blackberry & {
					line-height: $project-form-control-height-SMALL;
				}
			}

			// large
			&.input-lg {
				height: $project-form-control-height-LARGE;

				.blackberry & {
					line-height: $project-form-control-height-LARGE;
				}
			}
		}

		// select
		select.form-control {
			height: $project-form-control-height;
			line-height: 1;

			.blackberry & {
				line-height: $project-form-control-height;
			}

			option {
				font-family: $project-form-control-font-family;
			}

			// small
			&.input-sm {
				height: $project-form-control-height-SMALL;

				.blackberry & {
					line-height: $project-form-control-height-SMALL;
				}
			}

			// large
			&.input-lg {
				height: $project-form-control-height-LARGE;

				.blackberry & {
					line-height: $project-form-control-height-LARGE;
				}
			}

			// select skin
			& {
				@include css3-prefix(appearance, none);
				@include css3-background-size($project-select-skin-background-size-x, $project-select-skin-background-size-y);
				background-image: url($project-select-skin-background-image);
				background-position: right $project-select-skin-background-position center;
				background-repeat: no-repeat;

				// IE fix - only in 10+
				.ie &::-ms-expand {
					display: none;
				}

				// small
				&.input-sm {
					@include css3-background-size($project-select-skin-background-size-x-SMALL, $project-select-skin-background-size-y-SMALL);
					background-position: right $project-select-skin-background-position-SMALL center;
				}

				// large
				&.input-lg {
					@include css3-background-size($project-select-skin-background-size-x-LARGE, $project-select-skin-background-size-y-LARGE);
					background-position: right $project-select-skin-background-position-LARGE center;
				}
			}
		}

		// textarea
		textarea.form-control {
			@include css3-prefix(appearance, none);
		}

	// checkbox / radio button
	.checkbox,
	.radio {
		margin-bottom: 0;
		margin-top: 0;
	}

	// label
	.control-label {
		color: $project-label-color;
		font-family: $project-label-font-family;
		height: auto;
		font-size: $project-label-font-size;
		line-height: $project-label-line-height;
		margin-bottom: 0;

		// small
		&.label-sm {
			font-size: $project-label-font-size-SMALL;
			line-height: $project-label-line-height-SMALL;
		}

		// large
		&.label-lg {
			font-size: $project-label-font-size-LARGE;
			line-height: $project-label-line-height-LARGE;
		}
	}

	.checkbox label,
	.radio label {
		color: $project-label-checkradio-color;
		font-family: $project-label-checkradio-font-family;
		font-size: $project-label-checkradio-font-size;
		line-height: $project-label-checkradio-line-height;
		height: auto;

		// small
		&.label-sm {
			font-size: $project-label-checkradio-font-size-SMALL;
			line-height: $project-label-checkradio-line-height-SMALL;
		}

		// large
		&.label-lg {
			font-size: $project-label-checkradio-font-size-LARGE;
			line-height: $project-label-checkradio-line-height-LARGE;
		}
	}

	// help block
	.help-block {
		color: $project-help-block-color;
		font-family: $project-help-block-font-family;
		font-size: $project-help-block-font-size;
		line-height: $project-help-block-line-height;
		margin: $project-help-block-margin;
	}

	// error
	.has-error .form-control,
	.form-control.error {
		@include css3-placeholder($project-form-control-color-placeholder-ERROR);
		@include project-form-control-box-shadow-ERROR();
		background-color: $project-form-control-background-color-ERROR;
		color: $project-form-control-color-ERROR;
		border-color: $project-form-control-border-color-ERROR;
	}

	.has-error .control-label,
	.control-label.error {
		color: $project-label-color-ERROR;
	}

	.has-error .checkbox label,
	.has-error .radio label,
	.checkbox label.error,
	.radio label.error {
		color: $project-label-checkradio-color-ERROR;
	}

	.has-error .help-block {
		color: $project-help-block-color-ERROR;
	}

	// disabled
	.disabled .form-control,
	.form-control.disabled {
		@include css3-placeholder($project-form-control-color-placeholder-DISABLED);
		@include project-form-control-box-shadow-DISABLED();
		background-color: $project-form-control-background-color-DISABLED;
		color: $project-form-control-color-DISABLED;
		border-color: $project-form-control-border-color-DISABLED;
	}

	.disabled .control-label,
	.control-label.disabled {
		color: $project-label-color-DISABLED;
		cursor: not-allowed;
	}

	.disabled .checkbox label,
	.disabled .radio label,
	.checkbox label.disabled,
	.radio label.disabled {
		color: $project-label-checkradio-color-DISABLED;
		cursor: not-allowed;
	}

	.disabled .help-block {
		color: $project-help-block-color-DISABLED;
	}

	// version 1

		// form control
		.form-control {

			&.input-version-1 {
				@include css3-border-radius($project-form-control-border-radius-version-1);
				@include css3-placeholder($project-form-control-color-version-1-placeholder);
				@include project-form-control-box-shadow-version-1();
				background-color: $project-form-control-background-color-version-1;
				border-color: $project-form-control-border-color-version-1;
				border-width: $project-form-control-border-width-version-1;
				color: $project-form-control-color-version-1;
				font-family: $project-form-control-font-family-version-1;
				font-size: $project-form-control-font-size-version-1;
				padding: $project-form-control-padding-version-1;

				&:focus {
					@include css3-placeholder($project-form-control-color-version-1-placeholder-FOCUS);
					@include project-form-control-box-shadow-version-1-FOCUS();
					background-color: $project-form-control-background-color-version-1-FOCUS;
					border-color: $project-form-control-border-color-version-1-FOCUS;
					color: $project-form-control-color-version-1-FOCUS;
				}
			}

			// small
			&.input-sm {

				&.input-version-1 {
					@include css3-border-radius($project-form-control-border-radius-version-1-SMALL);
					border-width: $project-form-control-border-width-version-1-SMALL;
					font-size: $project-form-control-font-size-version-1-SMALL;
					padding: $project-form-control-padding-version-1-SMALL;
				}
			}

			// large
			&.input-lg {

				&.input-version-1 {
					@include css3-border-radius($project-form-control-border-radius-version-1-LARGE);
					border-width: $project-form-control-border-width-version-1-LARGE;
					font-size: $project-form-control-font-size-version-1-LARGE;
					padding: $project-form-control-padding-version-1-LARGE;
				}
			}


			// form control fake to file upload skin
			&-fake {

				&.input-version-1 {
					height: $project-form-control-height-version-1;
					line-height: $project-form-control-height-version-1;
				}

				// small
				&.input-sm {

					&.input-version-1 {
						height: $project-form-control-height-version-1-SMALL;
						line-height: $project-form-control-height-version-1-SMALL;
					}

					.blackberry & {

						&.input-version-1 {
							line-height: $project-form-control-height-version-1-SMALL;
						}
					}
				}

				// large
				&.input-lg {

					&.input-version-1 {
						height: $project-form-control-height-version-1-LARGE;
						line-height: $project-form-control-height-version-1-LARGE;
					}

					.blackberry & {

						&.input-version-1 {
							line-height: $project-form-control-height-version-1-LARGE;
						}
					}
				}
			}
		}

			// input
			input.form-control {

				&.input-version-1 {
					height: $project-form-control-height-version-1;
				}

				// small
				&.input-sm {

					&.input-version-1 {
						height: $project-form-control-height-version-1-SMALL;
					}

					.blackberry & {

						&.input-version-1 {
							line-height: $project-form-control-height-version-1-SMALL;
						}
					}
				}

				// large
				&.input-lg {

					&.input-version-1 {
						height: $project-form-control-height-version-1-LARGE;
					}

					.blackberry & {

						&.input-version-1 {
							line-height: $project-form-control-height-version-1-LARGE;
						}
					}
				}
			}

			// select
			select.form-control {

				&.input-version-1 {
					height: $project-form-control-height-version-1;
				}

				.blackberry & {

					&.input-version-1 {
						line-height: $project-form-control-height-version-1;
					}
				}

				option {
					font-family: $project-form-control-font-family-version-1;
				}

				// small
				&.input-sm {

					&.input-version-1 {
						height: $project-form-control-height-version-1;
					}

					.blackberry & {

						&.input-version-1 {
							line-height: $project-form-control-height-version-1-SMALL;
						}
					}
				}

				// large
				&.input-lg {

					&.input-version-1 {
						height: $project-form-control-height-version-1-LARGE;
					}

					.blackberry & {

						&.input-version-1 {
							line-height: $project-form-control-height-version-1-LARGE;
						}
					}
				}

				// select skin
				& {

					&.input-version-1 {
						@include css3-background-size($project-select-skin-background-size-x-version-1, $project-select-skin-background-size-y-version-1);
						background-image: url($project-select-skin-background-image-version-1);
						background-position: right $project-select-skin-background-position-version-1 center;

						// small
						&.input-sm {
							@include css3-background-size($project-select-skin-background-size-x-version-1-SMALL, $project-select-skin-background-size-y-version-1-SMALL);
							background-position: right $project-select-skin-background-position-version-1-SMALL center;
						}

						// large
						&.input-lg {
							@include css3-background-size($project-select-skin-background-size-x-version-1-LARGE, $project-select-skin-background-size-y-version-1-LARGE);
							background-position: right $project-select-skin-background-position-version-1-LARGE center;
						}
					}
				}
			}

		// label
		.control-label {

			&.label-version-1 {
				color: $project-label-checkradio-color-version-1;
				font-family: $project-label-font-family-version-1;
				font-size: $project-label-font-size-version-1;
				line-height: $project-label-line-height-version-1;

				// small
				&.label-sm {
					font-size: $project-label-font-size-version-1-SMALL;
					line-height: $project-label-line-height-version-1-SMALL;
				}

				// large
				&.label-lg {
					font-size: $project-label-font-size-version-1-LARGE;
					line-height: $project-label-line-height-version-1-LARGE;
				}
			}
		}

		.checkbox label,
		.radio label {

			&.label-version-1 {
				color: $project-label-checkradio-color-version-1;
				font-family: $project-label-checkradio-font-family-version-1;
				font-size: $project-label-checkradio-font-size-version-1;
				line-height: $project-label-checkradio-line-height-version-1;

				// small
				&.label-sm {
					font-size: $project-label-checkradio-font-size-version-1-SMALL;
					line-height: $project-label-checkradio-line-height-version-1-SMALL;
				}

				// large
				&.label-lg {
					font-size: $project-label-checkradio-font-size-version-1-LARGE;
					line-height: $project-label-checkradio-line-height-version-1-LARGE;
				}
			}
		}
		// help block
		.help-block {

			&.help-block-version-1 {
				color: $project-help-block-color-version-1;
				font-family: $project-help-block-font-family-version-1;
				font-size: $project-help-block-font-size-version-1;
				line-height: $project-help-block-line-height-version-1;
				margin: $project-help-block-margin-version-1;
			}
		}

		// error
		.has-error .form-control,
		.form-control.error {

			&.input-version-1 {
				@include css3-placeholder($project-form-control-color-version-1-placeholder-ERROR);
				@include project-form-control-box-shadow-version-1-ERROR();
				background-color: $project-form-control-background-color-version-1-ERROR;
				color: $project-form-control-color-version-1-ERROR;
				border-color: $project-form-control-border-color-version-1-ERROR;
			}
		}

		.has-error .control-label,
		.control-label.error {

			&.label-version-1 {
				color: $project-label-checkradio-color-version-1-ERROR;
			}
		}

		.has-error .checkbox label,
		.has-error .radio label,
		.checkbox label.error,
		.radio label.error {

			&.label-version-1 {
				color: $project-label-checkradio-color-version-1-ERROR;
			}
		}

		.has-error .help-block {

			&.help-block-version-1 {
				color: $project-help-block-color-version-1-ERROR;
			}
		}

		// disabled
		.disabled .form-control,
		.form-control.disabled {

			&.input-version-1 {
				@include css3-placeholder($project-form-control-color-version-1-placeholder-DISABLED);
				@include project-form-control-box-shadow-version-1-DISABLED();
				background-color: $project-form-control-background-color-version-1-DISABLED;
				color: $project-form-control-color-version-1-DISABLED;
				border-color: $project-form-control-border-color-version-1-DISABLED;
			}
		}

		.disabled .control-label,
		.control-label.disabled {

			&.label-version-1 {
				color: $project-label-checkradio-color-version-1-DISABLED;
			}
		}

		.disabled .checkbox label,
		.disabled .radio label,
		.checkbox label.disabled,
		.radio label.disabled {

			&.label-version-1 {
				color: $project-label-checkradio-color-version-1-DISABLED;
			}
		}

		.disabled .help-block {

			&.help-block-version-1 {
				color: $project-help-block-color-DISABLED;
			}
		}
}

/* forms END */
