/*** Project fonts ***/

/* fonts START */

@include css3-font-face(Demo-Font, demofont);
@include css3-font-face(ProximaNova-Regular_1, ProximaNova-Regular_1);
@include css3-font-face(ProximaNova-Semibold_1, ProximaNova-Semibold_1);
@include css3-font-face(ProximaNova-Bold_1, ProximaNova-Bold_1);
@include css3-font-face(PT_Serif-Web-Bold, PT_Serif-Web-Bold);

/* fonts END */
