/*** Project icons ***/

/* Font Awesome START */

// import Font Awesome font from maxcdn
@import 'http://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css';
// !! Available in the icomoon library too - https://icomoon.io/app/#/select/library

/* Font Awesome END */

/* icomoon START */

// include icomoon icons font
@include css3-font-face(icomoon, icomoon);

// setup icomoon
@mixin icomoon() {
	font-family: 'icomoon';
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 0.1;
	speak: none;
	position: relative;
	top: 10px;
	font-size: 34px;
	width: 44px;
	text-transform: none;

	// Better Font Rendering
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icomoon,
[class^="icomoon-"],
[class*=" icomoon-"] {
	@include icomoon();

}
.icomoon-price{
	top:3px;
	left: 9px;
	font-size: 18px;
}

.icomoon-8-ways{
	font-size: 14px;
	width: auto;
	top: 0;
}

// setup icomoon classes
.icomoon-8-ways:before {
	content: "\e600";
}
.icomoon-gbart:before {
	content: "\e601";
}
.icomoon-kind-of-good:before {
	content: "\e900";
}
.icomoon-living-space-1:before {
	content: "\e90a";
}
.icomoon-surface-ppe:before {
	content: "\e90b";
}
.icomoon-useful-surface:before {
	content: "\e90b";
}
.icomoon-availability-1:before {
	content: "\e90c";
}
.icomoon-land-area:before {
	content: "\e90d";
}
.icomoon-floor:before {
	content: "\e90e";
}
.icomoon-levels-above-ground:before {
	content: "\e90f";
}
.icomoon-rooms-1:before {
	content: "\e910";
}
.icomoon-bedrooms-1:before {
	content: "\e911";
}
.icomoon-bathrooms-1:before {
	content: "\e912";
}
.icomoon-fireplace:before {
	content: "\e913";
}
.icomoon-places-park-1:before {
	content: "\e914";
}
.icomoon-garden:before {
	content: "\e915";
}
.icomoon-balcony-1:before {
	content: "\e916";
}
.icomoon-balcony:before {
	content: "\e917";
}
.icomoon-terrace:before {
	content: "\e917";
}
.icomoon-loggia:before {
	content: "\e918";
}
.icomoon-swimming-pool:before {
	content: "\e919";
}
.icomoon-price-1:before {
	content: "\e91a";
}
.icomoon-construction-year-01:before {
	content: "\e91b";
}
.icomoon-year-renovation:before {
	content: "\e91c";
}
.icomoon-heating:before {
	content: "\e91d";
}
.icomoon-availability:before {
	content: "\e9";
}
.icomoon-energy-label-1:before {
	content: "\e91f";
}
.icomoon-construction-year-2 .path1:before {
	content: "\e901";
	color: rgb(0, 0, 0);
}
.icomoon-construction-year-2 .path2:before {
	content: "\e909";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
.icomoon-construction-year:before {
	content: "\e902";
}
.icomoon-bedrooms:before {
	content: "\e903";
}
.icomoon-energy-label:before {
	content: "\e904";
}
.icomoon-rooms:before {
	content: "\e905";
}
.icomoon-places-park:before {
	content: "\e906";
}
.icomoon-bathrooms:before {
	content: "\e907";
}
.icomoon-living-space:before {
	content: "\e908";
}
.icomoon-price:before {
	content: "\e91e";
}



/* icomoon END */
