/*** Project positions ***/

/* positions START */

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.fixed {
	position: fixed;
}

	// mobile
	@media (max-width: $screen-xs-max) {

		.xs-relative {
			position: relative;
		}

		.xs-absolute {
			position: absolute;
		}

		.xs-fixed {
			position: fixed;
		}
	}

	// tablet vertical
	@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {

		.sm-relative {
			position: relative;
		}

		.sm-absolute {
			position: absolute;
		}

		.sm-fixed {
			position: fixed;
		}
	}

	// tablet horizontal
	@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {

		.md-relative {
			position: relative;
		}

		.md-absolute {
			position: absolute;
		}

		.md-fixed {
			position: fixed;
		}
	}

	// desktop
	@media (min-width: $screen-lg-min) {

		.dt-relative {
			position: relative;
		}

		.dt-absolute {
			position: absolute;
		}

		.dt-fixed {
			position: fixed;
		}
	}

	// laptop
	@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {

		.lg-relative {
			position: relative;
		}

		.lg-absolute {
			position: absolute;
		}

		.lg-fixed {
			position: fixed;
		}
	}

	// HD
	@media (min-width: $screen-xl-min) {

		.hd-relative {
			position: relative;
		}

		.hd-absolute {
			position: absolute;
		}

		.hd-fixed {
			position: fixed;
		}
	}

/* positions END */
