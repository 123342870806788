/*** Project cookiebar ***/

/* cookiebar START */

div.cookie-bar {
	@include css3-background-opacity($project-cookie-bar-layer-background-color, $project-cookie-bar-layer-opacity);
	@include css3-prefix(transform, translate3d(0, 0, 4px));
	@include project-cookie-bar-layer-box-shadow();
	@include project-default-transition();
	bottom: 0;
	color: $project-cookie-bar-text-color;
	font-family: $project-cookie-bar-text-font-family;
	font-size: $project-cookie-bar-text-font-size;
	height: auto;
	left: 0;
	line-height: $project-cookie-bar-text-line-height;
	min-height: $project-cookie-bar-close-height + ( $project-cookie-bar-layer-padding * 2 );
	padding-bottom: $project-cookie-bar-layer-padding + ( ( $project-cookie-bar-close-height - $project-cookie-bar-text-line-height ) / 2 );
	padding-left: $project-cookie-bar-layer-padding + ( ( $project-cookie-bar-close-height - $project-cookie-bar-text-line-height ) / 2 );
	padding-right: $project-cookie-bar-layer-padding * 2 + $project-cookie-bar-close-width + ( ( $project-cookie-bar-close-height - $project-cookie-bar-text-line-height ) / 2 );
	padding-top: $project-cookie-bar-layer-padding + ( ( $project-cookie-bar-close-height - $project-cookie-bar-text-line-height ) / 2 );
	position: fixed;
	right: 0;
	text-align: center;
	top: auto;
	word-wrap: break-word;
	width: auto;
	z-index: 4;

	// mobile
	@media (max-width: $screen-xs-max) {
		bottom: auto;
		position: absolute;
		top: 0;
	}

	a {
		@include project-default-transition();
		color: $project-cookie-bar-link-color;
		font-family: $project-cookie-bar-link-font-family;
		text-decoration: none;

		// desktop
		@media (min-width: $screen-lg-min) {
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}

		// close button
		&.acceptCookies {
			@include css3-border-radius($project-cookie-bar-close-border-radius);
			background-color: $project-cookie-bar-close-background-color;
			border: $project-cookie-bar-close-border-width solid $project-cookie-bar-close-border-color;
			color: $project-cookie-bar-close-color;
			display: block;
			float: none;
			font-family: $project-cookie-bar-close-font-family;
			font-size: $project-cookie-bar-close-font-size;
			height: $project-cookie-bar-close-height;
			line-height: $project-cookie-bar-close-height - ( $project-cookie-bar-close-border-width * 2 );
			overflow: hidden;
			padding: 0;
			position: absolute;
			right: $project-cookie-bar-layer-padding;
			text-align: center;
			top: $project-cookie-bar-layer-padding;
			width: $project-cookie-bar-close-width;

			// desktop
			@media (min-width: $screen-lg-min) {
				&:hover,
				&:focus {
					background-color: $project-cookie-bar-close-background-color-HOVER;
					border-color: $project-cookie-bar-close-border-color-HOVER;
					color: $project-cookie-bar-close-color-HOVER;
					text-decoration: none;
				}
			}
		}
	}
}

/* cookiebar END */
