/*** Admin modifications ***/

/* admin START */

//.top-margin {
//	margin-top: 50px;
//}
//
//#ccm-highlighter {
//	z-index: 555;
//}

.edit-advices .advice{
	cursor: pointer;
}

/* admin END */
