/*** Project layout ***/


/* html + body START */

html,
body {
/*
	// mobile
	@media (max-width: $screen-xs-max) {
		height: 100%;
		overflow-y: scroll;
		width: 100%;
		-webkit-overflow-scrolling: touch;
	}
*/
}

html {}

body {}

/* html + body END */

/* main START */

.main {
	background: $color-white;
	min-width: 320px;
	overflow: hidden;
	position: relative;

	.container-fluid.relative{
		z-index: 1;
	}

	.noclick{
		pointer-events: none;
	}
}

/* main END */

/* header START */

.header {
	position: relative;

	nav{
		.clearfix div:last-child ul li:last-child{
			// desktop
			@media (min-width: $screen-lg-min) {
				position: absolute;
				right: 20px;
				bottom: 0px;
			}

			// tablet horizontal
			@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
				position: absolute;
				right: 0px;
				bottom: 0px;
			}
		}
	}
}

/* header END */

/* contents START */

.contents {}

	/* content START */

	.content {}

	/* content END */

	/* sidebar START */

	.sidebar {}

	/* sidebar END */

/* contents END */

/* footer START */

.footer {}

/* footer END */
