/*** Project grid extra ***/

/* grid extra START */

/* hide helpers */
.hide {
	display: none !important;
}

	// mobile
	@media (max-width: $screen-xs-max) {

		.xs-hide {
			display: none !important;
		}
	}

	// tablet vertical
	@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {

		.sm-hide {
			display: none !important;
		}
	}

	// tablet horizontal
	@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {

		.md-hide {
			display: none !important;
		}
	}

	// desktop
	@media (min-width: $screen-lg-min) {

		.dt-hide {
			display: none !important;
		}
	}

	// laptop
	@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {

		.lg-hide {
			display: none !important;
		}
	}

	// HD
	@media (min-width: $screen-xl-min) {

		.xl-hide {
			display: none !important;
		}
	}


/* centered columns - more info: http://www.minimit.com/demos/bootstrap-3-responsive-centered-columns */
// for 1 column
.center-block {
	float: none;
}

// for 2+ column
.row-centered {
	text-align: center;
}

	.col-centered {
		display: inline-block;
		float: none;
		margin-right: -4px; // inline-block space fix
		text-align: left; // reset the text-align
	}

/* columns with same height - More info: http://www.minimit.com/demos/bootstrap-3-responsive-columns-of-same-height */
.row-same-height {
	display: table;
	width: 100%;
	table-layout: fixed; // fix overflow
}

	.col-same-height {
		display: table-cell;
		float: none !important;
	}

		// mobile
		@media (max-width: $screen-xs-max) {
			.col-xs-same-height {
				display: table-cell;
				float: none !important;
			}
		}

		// tablet vertical
		@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
			.col-sm-same-height {
				display: table-cell;
				float: none !important;
			}
		}

		// tablet horizontal
		@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
			.col-md-same-height {
				display: table-cell;
				float: none !important;
			}
		}

		// desktop
		@media (min-width: $screen-lg-min) {
			.col-dt-same-height {
				display: table-cell;
				float: none !important;
			}
		}

		// laptop
		@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
			.col-lg-same-height {
				display: table-cell;
				float: none !important;
			}
		}

		// HD
		@media (min-width: $screen-xl-min) {
			.col-xl-same-height {
				display: table-cell;
				float: none !important;
			}
		}

	// if you want to use the "height: 100%" in the column - BUG IN IE!
//	.row-full-height {
//		height: 100%;
//	}
//
//		.col-full-height {
//			height: 100%;
//			vertical-align: middle;
//		}


	// if you want to give position to the content in the column
	.col-top {
		vertical-align: top;
	}

	.col-middle {
		vertical-align: middle;
	}

	.col-bottom {
		vertical-align: bottom;
	}

/* grid extra END */
