/*** Project embed responsive layer ***/

/* embed responsive layer START */

.embed-responsive-layer {
	@include css3-background-opacity($project-embed-responsive-layer-background-color, $project-embed-responsive-layer-background-opacity);
	@include css3-opacity($project-embed-responsive-layer-opacity);
	@include project-default-transition();
	cursor: pointer;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	overflow: hidden;

	p {
		color: $project-embed-responsive-layer-color;
		font-family: $project-embed-responsive-layer-font-family;
		font-size: $project-embed-responsive-layer-font-size;
		height: 1000px;
		left: 0;
		line-height: 1000px;
		margin-top: -500px;
		position: absolute;
		right: 0;
		text-align: center;
		top: 50%;
	}

	// desktop
	@media (min-width: $screen-lg-min) {
		&:hover,
		&:focus {
			@include css3-background-opacity($project-embed-responsive-layer-background-color-HOVER, $project-embed-responsive-layer-background-opacity-HOVER);
			@include css3-opacity($project-embed-responsive-layer-opacity-HOVER);
		}
	}

	// mobile + tablet
	@media (max-width: $screen-md-max) {
		@include css3-opacity($project-embed-responsive-layer-opacity-HOVER);
	}

	// hide layer
	&.fade-out-layer {
		opacity: 0;
	}
}

/* embed responsive layer END */
