/*** Project embed responsive layer for youtube ***/

/* embed responsive layer for youtube START */

.embed-responsive-layer-youtube {
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	cursor: pointer;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;

	p {
		@include css3-background-opacity($project-embed-responsive-layer-youtube-background-color, $project-embed-responsive-layer-youtube-background-opacity);
		@include css3-opacity($project-embed-responsive-layer-youtube-opacity);
		@include project-default-transition();
		color: $project-embed-responsive-layer-youtube-color;
		font-family: $project-embed-responsive-layer-youtube-font-family;
		font-size: $project-embed-responsive-layer-youtube-font-size;
		height: 1000px;
		left: 0;
		line-height: 1000px;
		margin-top: -500px;
		position: absolute;
		right: 0;
		text-align: center;
		top: 50%;

		// desktop
		@media (min-width: $screen-lg-min) {
			&:hover,
			&:focus {
				@include css3-background-opacity($project-embed-responsive-layer-youtube-background-color-HOVER, $project-embed-responsive-layer-youtube-background-opacity-HOVER);
				@include css3-opacity($project-embed-responsive-layer-youtube-opacity-HOVER);
			}
		}

		// mobile + tablet
		@media (max-width: $screen-md-max) {
			@include css3-opacity($project-embed-responsive-layer-youtube-opacity-HOVER);
		}
	}

	// hide layer
	&.fade-out-layer {
		opacity: 0;
	}
}

/* embed responsive layer for youtube END */
